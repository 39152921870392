'use client';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { PortableText } from '@portabletext/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import { urlForImage } from '@lib/sanity/image';
import 'swiper/css';
import 'swiper/css/pagination';
import type { ReviewDocument } from '@lib/sanity/types';
import { EmptyStarIcon, StarIcon } from '@public/icons';

type ReviewsCarouselProps = {
  reviews?: ReviewDocument[];
};

export default function ReviewsCarousel({ reviews }: ReviewsCarouselProps): JSX.Element {
  if (!reviews || reviews.length === 0) {
    return <div className="hidden">No reviews have been associated to this component</div>;
  }

  return (
    <Swiper
      breakpoints={{
        520: {
          pagination: {
            enabled: reviews.length > 2,
          },
          slidesPerView: reviews.length >= 2 ? 2 : reviews.length,
          spaceBetween: 20,
        },
        1040: {
          pagination: {
            enabled: reviews.length > 2,
          },
          slidesPerView: reviews.length >= 3 ? 3 : reviews.length,
          spaceBetween: 20,
        },
        1300: {
          pagination: {
            enabled: reviews.length > 2,
          },
          slidesPerView: reviews.length >= 4 ? 4 : reviews.length,
          spaceBetween: 20,
        },
      }}
      className="w-full max-w-screen-lg"
      loop={false}
      modules={[Pagination]}
      pagination={{
        clickable: true,
        enabled: true,
      }}
      slidesPerView={1}
      spaceBetween={10}
    >
      {reviews.map((review) => {
        return (
          <SwiperSlide className="!h-auto" key={review._key}>
            <Card
              className="w-full h-full max-w-[30rem] bg-[#F6F6F6] rounded-[0.625rem] px-6 py-5 sm:px-7 sm:py-7"
              elevation={0}
            >
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="terapist"
                    className="w-16 h-16 md:w-20 md:h-20"
                    src={review.icon ? urlForImage(review.icon).url() : ''}
                  >
                    {review.title}
                  </Avatar>
                }
                className="p-0 pb-4"
                subheader={
                  <Rating
                    emptyIcon={<EmptyStarIcon className="ml-1 h-4 w-4" />}
                    icon={<StarIcon className="ml-1 h-4 w-4" />}
                    name="read-only"
                    readOnly
                    value={review.stars}
                  />
                }
                title={review.title}
                titleTypographyProps={{
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                  fontWeight: 700,
                }}
              />
              <CardContent className="!py-0 !px-0">
                <div className="text-sm text-black-base line-clamp-6">
                  <PortableText value={review.shortDescription ?? []} />
                </div>
              </CardContent>
            </Card>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
