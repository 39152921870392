'use client';

import Rating from '@mui/material/Rating';
import { PortableText } from '@portabletext/react';
import { useSmoothScrollTo } from 'ui/hooks';
import type { ReviewDocument, ReviewSmallDocument } from '@lib/sanity/types';
import { EmptyStarIcon, StarIcon } from '@public/icons';
import ReviewsCarousel from './reviews-carousel';

type Props = {
  data: {
    _type: string;
    bgColor: string;
    name: string;
    headline: string;
    anchor?: string;
    review?: ReviewSmallDocument['review'];
    reviews?: ReviewDocument[];
  };
};

export default function Review({ data }: Props): JSX.Element | null {
  const bind = useSmoothScrollTo(`#${data.anchor}`);
  if (!data.reviews || data.reviews.length === 0) {
    return null;
  }
  return (
    <section
      className={`flex flex-col w-full justify-center items-center py-6 lg:py-20 px-6 md:px-10 lg:px-16 ${
        data.bgColor ? data.bgColor : 'bg-white'
      }`}
      id={data.anchor}
      {...(data.anchor ? { ...bind } : null)}
    >
      <div className="flex w-full justify-center mb-6 lg:mb-8">
        <div className="flex gap-x-8 flex-col justify-center items-center w-full max-w-screen-md">
          <h3 className="w-full pb-2 md:pb-5 text-2xl lg:text-4xl text-primary font-bold leading-[1.875rem] lg:leading-10 text-center">
            {data.headline}
          </h3>
          <div className="flex flex-col lg:flex-row text-xs md:text-base text-black-base text-left md:text-center gap-2">
            <div className="flex flex-row gap-1 justify-center items-center">
              <PortableText value={data.review?.title ?? []} />
              <Rating
                emptyIcon={<EmptyStarIcon className="ml-1 h-5 w-5" />}
                icon={<StarIcon className="ml-1 h-5 w-5" />}
                name="read-only"
                readOnly
                value={data.review?.count}
              />
            </div>
            <div className="self-center">
              <PortableText value={data.review?.text ?? []} />
            </div>
          </div>
        </div>
      </div>
      <ReviewsCarousel reviews={data.reviews} />
    </section>
  );
}
