'use client';

import { PortableText } from '@portabletext/react';
import { useSmoothScrollTo } from 'ui/hooks';
import type { TypedObject } from '@portabletext/types';
import type { RecommendedTerapistDocument } from '@lib/sanity/types';
import RecommendedCarousel from './recommended-carousel';

type Props = {
  data: {
    _type?: string;
    bgColor: string;
    name: string;
    anchor?: string;
    headline?: string;
    subheadline?: TypedObject[];
    terapists?: RecommendedTerapistDocument[];
  };
};

export default function RecommendedBy({ data }: Props): JSX.Element | null {
  const bind = useSmoothScrollTo(`#${data.anchor}`);
  if (!data.terapists || data.terapists.length === 0) {
    return null;
  }
  return (
    <section
      className={`flex flex-col w-full justify-center items-center px-6 md:px-10 lg:px-16 pt-8 sm:pt-10 lg:pt-20 pb-0 sm:pb-10 lg:pb-20 ${
        data.bgColor ? data.bgColor : 'bg-white'
      }`}
      id={data.anchor}
      {...(data.anchor ? { ...bind } : null)}
    >
      <div className="flex w-full justify-center max-w-screen-md">
        <div className="flex gap-x-8 flex-col w-full">
          <h3 className="pb-2 md:pb-5 text-2xl lg:text-4xl text-primary font-bold leading-[1.875rem] lg:leading-10 text-left sm:text-center">
            {data.headline}
          </h3>
          <div className="pb-6 md:pb-8 text-sm md:text-base text-black-base text-left sm:text-center">
            <PortableText value={data.subheadline ?? []} />
          </div>
        </div>
      </div>
      <RecommendedCarousel terapists={data.terapists} />
    </section>
  );
}
