'use client';

import { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import IconButton from '@mui/material/IconButton';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
// import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useWindowSize } from 'ui/hooks';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTranslation, type TranslationsDictionary } from '@lib/i18n';
import type { Settings } from '@lib/sanity/types';
import { locales } from '@lib/i18n/locales';
import MenuItem from './menu-item';

export default function Header({ config, dict }: { config?: Settings; dict: TranslationsDictionary }): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const t = useTranslation(dict);
  const { width } = useWindowSize();
  const onEscapeKeyPressed = useCallback((event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      setIsMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    if (width >= 1200) return;

    // prevents the mobile menu from flickering when resizing the window from desktop to mobile
    setIsMenuVisible(false);

    setTimeout(() => {
      setIsMenuVisible(true);
    }, 0);
  }, [width]);

  useEffect(() => {
    document.addEventListener('keydown', onEscapeKeyPressed, false);

    return () => {
      document.removeEventListener('keydown', onEscapeKeyPressed, false);
    };
  }, [onEscapeKeyPressed]);

  function toggleMenu(): void {
    setIsMenuOpen((prevState) => !prevState);

    if (isMenuOpen) {
      document.body.classList.remove('no-scroll');

      return;
    }

    document.body.classList.add('no-scroll');
  }

  const currentLocale = locales.find((locale) => locale.id === config?.language)?.path;

  return (
    <>
      <div className="fixed lg:relative z-30 flex flex-row w-full bg-primary items-center justify-center px-0 md:px-8 lg:px-16">
        <div className="w-72 h-1 lg:w-[18.75rem] bg-[#6CC24A]" />
        <div className="w-72 h-1 lg:w-[18.75rem] bg-[#FFC658]" />
        <div className="w-72 h-1 lg:w-[18.75rem] bg-[#FF8F1C]" />
        <div className="w-72 h-1 lg:w-[18.75rem] bg-[#F9423A]" />
      </div>
      <header className="fixed lg:relative flex w-full justify-center h-14 md:h-16 lg:h-24 px-2 md:px-8 lg:px-16 transition-colors z-20 text-white bg-primary">
        <div className="flex lg:flex-nowrap w-full max-w-screen-lg">
          {isMenuOpen ? (
            <div className="flex flex-row w-full justify-end">
              <IconButton aria-label={t('header.closeMenu')} className="lg:hidden z-[11] self-end" onClick={toggleMenu}>
                <CloseOutlinedIcon className="text-white" />
              </IconButton>
            </div>
          ) : (
            <IconButton aria-label={t('header.openMenu')} className="lg:hidden z-[11]" onClick={toggleMenu}>
              <MenuOutlinedIcon className="text-white" />
            </IconButton>
          )}

          {!isMenuOpen ? (
            <div className="flex flex-row w-full lg:flex-col lg:w-auto justify-center items-center mr-5 ">
              <Link
                aria-label={t('header.logoLinkAriaLabel')}
                className="flex justify-center items-center grow lg:grow-0 h-full focus-visible:outline-white focus-visible:outline-2 focus-visible:outline-offset-[-2px] z-10"
                href={config?.language ? `/${currentLocale}` : '/'}
              >
                <Image
                  alt="Fisiocrem"
                  className="w-auto h-[1.563rem] md:h-10"
                  height={40}
                  src="/logos/logo-full.png"
                  width={156}
                />
              </Link>
            </div>
          ) : null}
          {isMenuVisible ? (
            <nav
              className={`fixed lg:static top-14 md:top-16 lg:top-auto lg:left-auto left-0 right-0 bottom-0 flex basis-full lg:basis-auto order-last lg:order-none transition ease-in delay-150 duration-300 lg:transition-none bg-white lg:bg-transparent mx-auto ${
                isMenuOpen ? 'translate-y-0' : 'translate-y-[calc(-100%-4rem)] lg:transform-none'
              }`}
            >
              <ul className="flex flex-col lg:flex-row w-full px-9 md:px-10 lg:px-0 text-xl lg:text-[1.125rem] lg:leading-6 gap-7 font-semibold bg-primary">
                {isMenuOpen ? (
                  <Link
                    aria-label={t('header.logoLinkAriaLabel')}
                    className="inline focus-visible:outline-white focus-visible:outline-2 focus-visible:outline-offset-[-2px] z-10"
                    href="/"
                  >
                    <Image alt="Fisiocrem" className="w-auto h-9" height={40} src="/logos/logo-full.png" width={156} />
                  </Link>
                ) : null}
                {config?.mainMenuItems?.map((item) => <MenuItem item={item} key={item.label} />)}
              </ul>
            </nav>
          ) : null}
          {!isMenuVisible ? (
            <div className="flex justify-end items-center w-10 lg:w-[3.75rem] lg:pt-[0.375rem]">
              {/* TODO: Search functionality will be implemented in the future
          <IconButton aria-label="search" className={`${isMenuOpen ? 'hidden' : ''} text-white`}>
            <SearchOutlinedIcon />
          </IconButton>
          */}
            </div>
          ) : null}
        </div>
      </header>
    </>
  );
}
