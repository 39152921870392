import { useState } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import type { PortableTextBlock } from '@portabletext/types';
import { PortableText } from '@portabletext/react';
import { useTranslation } from '@lib/i18n';
import type { TranslationsDictionary } from '@lib/i18n';

type Props = {
  className?: string;
  data?: { couponTitle?: PortableTextBlock[]; couponCode?: string; couponCopyLabel?: string };
  translationsDictionary: TranslationsDictionary;
};

export default function Coupon({ className, translationsDictionary, data }: Props): JSX.Element | null {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [error, setError] = useState('');
  const t = useTranslation(translationsDictionary);

  const shouldRenderCoupon = data?.couponCode && data.couponCopyLabel && data.couponTitle;

  if (!shouldRenderCoupon) {
    return null;
  }

  function handleCopyToClipboard(): void {
    navigator.clipboard
      .writeText(t('coupon.code'))
      .then(() => {
        setIsTooltipOpen(true);
        setTimeout(() => {
          setIsTooltipOpen(false);
        }, 4000);
      })
      .catch(() => {
        setError(t('coupon.copyError'));
      });
  }

  return (
    <div
      className={`flex flex-col md:flex-row items-center justify-center gap-x-4 gap-y-2 w-full h-full p-2 bg-[#6C1D97] rounded-lg border-[#7A34A0] ${className}`}
    >
      <div className="flex items-center justify-center text-center text-[0.8125rem] text-white">
        <LocalShippingOutlinedIcon className="hidden w-10 h-10 sm:inline-block p-2 mr-2 rounded-full bg-[#6C1D97]" />
        <PortableText value={data.couponTitle || []} />
      </div>
      <Tooltip className="flex" open={isTooltipOpen} placement="top" title="Copiado al portapapeles">
        <div>
          <Button
            className="px-3 py-1 text-sm bg-white text-primary coupon-button-dashed-border hover:bg-white"
            disableElevation
            onClick={handleCopyToClipboard}
            variant="contained"
          >
            {data.couponCode}
          </Button>
          <Button
            className="ml-2 px-4 py-1 text-md font-semibold text-primary bg-white hover:bg-white"
            disableElevation
            onClick={handleCopyToClipboard}
            startIcon={<ContentCopyOutlinedIcon />}
            variant="text"
          >
            {data.couponCopyLabel}
          </Button>
          {error ? <p className="text-xs text-red-500">{error}</p> : null}
        </div>
      </Tooltip>
    </div>
  );
}
