'use client';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { PortableText } from '@portabletext/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { urlForImage } from '@lib/sanity/image';
import 'swiper/css';
import 'swiper/css/pagination';
import type { RecommendedTerapistDocument } from '@lib/sanity/types';

type ReviewsCarouselProps = {
  terapists?: RecommendedTerapistDocument[];
};

export default function RecommendedCarousel({ terapists }: ReviewsCarouselProps): JSX.Element {
  if (!terapists || terapists.length === 0) {
    return <div className="hidden">No terapists have been associated to this component</div>;
  }

  return (
    <Swiper
      breakpoints={{
        520: {
          pagination: {
            enabled: true,
          },
          slidesPerView: terapists.length >= 3 ? 2 : terapists.length,
          spaceBetween: 20,
        },
        1040: {
          pagination: {
            enabled: false,
          },
          slidesPerView: terapists.length >= 3 ? 3 : terapists.length,
          spaceBetween: 20,
        },
        1300: {
          pagination: {
            enabled: true,
          },
          slidesPerView: terapists.length >= 3 ? 3 : terapists.length,
          spaceBetween: 20,
        },
      }}
      className="w-full max-w-screen-lg"
      loop={false}
      modules={[Pagination]}
      pagination={{
        clickable: true,
        enabled: true,
      }}
      slidesPerView={1}
      spaceBetween={10}
    >
      {terapists.map((terapist) => {
        return (
          <SwiperSlide className="!h-auto" key={terapist._key}>
            <Card className="w-full h-full max-w-[30rem] rounded-[0.625rem] px-6 py-5 sm:px-7 sm:py-7" elevation={0}>
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="terapist"
                    className="w-20 h-20"
                    src={terapist.icon ? urlForImage(terapist.icon).url() : ''}
                  >
                    {terapist.title}
                  </Avatar>
                }
                className="p-0 pb-4"
                subheader={terapist.text}
                subheaderTypographyProps={{
                  fontSize: '0.813rem',
                  lineHeight: '1.125rem',
                  color: 'var(--mui-palette-text-primary)',
                }}
                title={terapist.title}
                titleTypographyProps={{
                  fontSize: '1.125rem',
                  lineHeight: '1.563rem',
                  fontWeight: 700,
                }}
              />
              <CardContent className="!py-0 !px-0">
                {terapist.shortDescription ? (
                  <div className="text-sm md:text-base text-black-base leading-6 line-clamp-[9]">
                    <PortableText value={terapist.shortDescription} />
                  </div>
                ) : null}
              </CardContent>
            </Card>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
