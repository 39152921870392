import Link from 'next/link';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { PortableText } from '@portabletext/react';
import { urlForImage } from '@lib/sanity/image';
import { useTranslation } from '@lib/i18n';
import type { TranslationsDictionary } from '@lib/i18n';
import 'swiper/css';
import 'swiper/css/pagination';
import type { BasicProductDocument } from '@lib/sanity/types';

type ListProductsProps = {
  products?: BasicProductDocument[];
  translationsDictionary: TranslationsDictionary;
  buttonText?: string;
  buttonVariant?: 'text' | 'contained' | 'outlined';
};

export default function ListProducts({
  products,
  translationsDictionary,
  buttonVariant,
  buttonText,
}: ListProductsProps): JSX.Element {
  const t = useTranslation(translationsDictionary);

  if (!products || products.length === 0) {
    return <div className="hidden">No products have been associated to this component</div>;
  }

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-12 max-w-screen-lg">
      {products.map((product) => {
        const currentProductUrl = `/${t('product.baseSegmentPath')}/${product.slug.current}`;

        return (
          <Card className="w-full flex flex-col" elevation={0} key={product._id}>
            <Link className="bg-accent-1 rounded-lg mb-4" href={currentProductUrl}>
              <CardMedia
                className="h-32 sm:h-64 bg-contain my-8 sm:my-0"
                image={product.image ? urlForImage(product.image).url() : ''}
                title={product.name}
              />
            </Link>
            <CardContent className="flex flex-col !p-0 md:p-4 grow">
              <h4 className="pb-0 sm:pb-2 text-lg md:text-xl text-primary font-bold leading-[1.625rem] line-clamp-3 text-center sm:text-left">
                <Link href={currentProductUrl}>{product.name}</Link>
              </h4>
              <div className="text-[0.875rem] md:text-base text-black-base line-clamp-[12] md:line-clamp-6 leading-5 text-center sm:text-start">
                <PortableText value={product.shortDescription ?? []} />
              </div>
            </CardContent>
            <CardActions className="px-0">
              <Button
                className={`text-[0.875rem] md:text-sm font-medium leading-5 w-full sm:w-auto ${buttonVariant ? 'text-left pl-0' : 'text-center'}`}
                color="secondary"
                disableElevation
                href={currentProductUrl}
                variant={buttonVariant || 'contained'}
              >
                {buttonText || t('productsCarousel.cta')}
              </Button>
            </CardActions>
          </Card>
        );
      })}
    </div>
  );
}
