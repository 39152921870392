'use client';

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Button from '@mui/material/Button';
import { StoreLocator as StoreLocatorInput, useGtmEvent } from 'ui/components';
import type { EventArguments } from 'ui/components';
import { PortableText } from '@portabletext/react';
import { useSmoothScrollTo } from 'ui/hooks';
import { CartIcon, LocationIcon, PadlockIcon } from '@public/icons';
import { useTranslation } from '@lib/i18n';
import type { TranslationsDictionary } from '@lib/i18n';
import type { StoreLocatorDocument } from '@lib/sanity/types';
import Coupon from './coupon';

type Props = {
  data?: StoreLocatorDocument;
  locale: string;
  translationsDictionary: TranslationsDictionary;
};

const BUY_NOW_TARGET_URL = 'https://www.naturitas.es/b/fisiocrem';
const purchaseEvent: EventArguments = {
  event: 'purchase',
  transaction_id: Date.now().toString(),
  currency: 'EUR',
  value: 1,
};

function StoreLocatorInputs({ data, locale, translationsDictionary }: Props): JSX.Element | null {
  const t = useTranslation(translationsDictionary);
  const pushEvent = useGtmEvent();

  function handleCtaClick(): void {
    pushEvent(purchaseEvent);
  }

  if (!data) {
    return null;
  }

  const isCompact = data.variant === 'compact';

  const couponData = {
    couponCode: data.couponCode,
    couponCopyLabel: data.couponCopyLabel,
    couponTitle: data.couponTitle,
  };

  const storeLocatorMessage =
    data.messageType === 'freeShipping' ? (
      <>
        <CartIcon className="h-4 w-4 md:h-5 md:w-5 mr-1.5" />
        {t('storeLocator.buyOnline')} <strong className="ml-1">{t('storeLocator.freeShipping')}</strong>
      </>
    ) : (
      <>
        <PadlockIcon className="h-4 w-4 md:h-5 md:w-5 mr-1.5" />
        {t('storeLocator.buyOnlineSecure')} <strong className="ml-1">{t('storeLocator.securePay')}</strong>
      </>
    );
  return (
    <div className="flex gap-x-8 flex-col justify-center items-center w-full max-w-screen-md" id="store-locator">
      {data.headline ? (
        <h3 className="w-full pb-2 text-2xl lg:text-4xl text-white font-bold leading-7 lg:leading-10 text-center">
          {data.headline}
        </h3>
      ) : null}
      <Coupon className="hidden sm:flex mb-5" data={couponData} translationsDictionary={translationsDictionary} />
      <div
        className={`flex flex-col sm:flex-row w-full ${
          isCompact
            ? 'items-end gap-x-2 lg:gap-x-4 gap-y-0 md:gap-y-4'
            : 'justify-center items-center gap-x-6 gap-y-2 md:gap-y-4'
        }`}
      >
        <div
          className={`flex flex-col w-full rounded-lg gap-2 px-4 sm:w-1/2
            ${isCompact ? 'sm:bg-transparent py-2 sm:py-0 sm:px-0' : 'py-0'}
            ${!isCompact && !data.showStoreLocator ? ' flex-col-reverse sm:w-1/2' : ''}
            `}
        >
          <Coupon className="sm:hidden" data={couponData} translationsDictionary={translationsDictionary} />
          <div
            className={`w-full text-center text-sm whitespace-nowrap ${isCompact ? 'text-primary' : 'text-white mt-0'}`}
          >
            <div
              className={`flex items-center md:items-start justify-center ${isCompact ? '' : 'lg:text-lg'} ${isCompact && !data.showStoreLocator ? 'sm:justify-start' : ''}`}
            >
              {data.ctaTitle ? (
                <>
                  <PadlockIcon className="h-4 w-4 md:h-5 md:w-5 mr-1.5" />
                  <PortableText value={data.ctaTitle} />
                </>
              ) : (
                <>{storeLocatorMessage}</>
              )}
            </div>
          </div>
          <Button
            className={`text-[1.125rem] font-medium whitespace-nowrap bg-[#6CC24A] hover:bg-[#6CC24A] self-center w-full ${isCompact ? 'mt-2 py-2 text-lg' : 'sm:mt-4 sm:text-xl'} ${!isCompact && !data.showStoreLocator ? 'sm:mb-2' : ''}`}
            disableElevation
            href={data.ctaButton ? data.ctaButton : BUY_NOW_TARGET_URL}
            onClick={handleCtaClick}
            startIcon={<ShoppingCartOutlinedIcon className="w-6 h-6" />}
            variant="contained"
          >
            {data.ctaLabel ? data.ctaLabel : t('storeLocator.buyNow')}
          </Button>
          {data.couponDisclaimer ? (
            <div className="sm:hidden w-full pt-3 text-center text-xs text-primary">{data.couponDisclaimer}</div>
          ) : null}
        </div>
        {data.showStoreLocator ? (
          <>
            <div
              className={`flex flex-col w-full sm:w-1/2 rounded-lg gap-2 mt-2 sm:mt-0 ${isCompact ? '' : 'px-3 py-4'} `}
            >
              <div className={`w-full text-center text-primary ${isCompact ? 'text-base sm:text-left' : 'text-base'}`}>
                {data.ctaInputTitle ? (
                  <PortableText value={data.ctaInputTitle} />
                ) : (
                  <div
                    className={`flex items-center justify-center w-full text-center text-sm text-nowrap ${isCompact ? 'text-primary sm:text-left' : 'text-white lg:text-lg'}`}
                  >
                    <LocationIcon className="h-4 w-4 md:h-5 md:w-5 mr-1.5" />
                    {t('storeLocator.findUs')} <strong className="ml-1">{t('storeLocator.closestPharmacy')}</strong>
                  </div>
                )}
              </div>

              <div className={isCompact ? 'px-2 sm:px-0' : ''}>
                <StoreLocatorInput
                  backgroundColor={isCompact ? 'bg-primary' : 'bg-[#6CC24A]'}
                  brand="fisiocrem"
                  countryCode={locale}
                  inputAriaLabel={t('storeLocator.inputAriaLabel')}
                  noLocationsFoundMsg={t('storeLocator.noLocationsFoundMsg')}
                  noOptionsText={t('storeLocator.noOptionsText')}
                  placeholder={t('storeLocator.placeholder')}
                  searchButtonAriaLabel={t('storeLocator.searchButtonAriaLabel')}
                  title={t('storeLocator.modalTitle')}
                  useMyLocationText={t('storeLocator.useMyLocationText')}
                  variant={data.variant}
                />
              </div>
            </div>
            {data.couponDisclaimer ? (
              <div className="hidden sm:block w-full p-3 text-center text-xs text-primary">{data.couponDisclaimer}</div>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
}

export default function StoreLocator({ data, locale, translationsDictionary }: Props): JSX.Element {
  const bind = useSmoothScrollTo(`#${data?.anchor}`);
  if (data?.variant === 'compact') {
    return <StoreLocatorInputs data={data} locale={locale} translationsDictionary={translationsDictionary} />;
  }

  return (
    <section
      className="flex flex-col w-full justify-center items-center py-6 bg-primary"
      id={data?.anchor}
      {...(data?.anchor ? { ...bind } : null)}
    >
      <div className="flex w-full justify-center px-6 md:px-10 lg:px-16">
        <StoreLocatorInputs data={data} locale={locale} translationsDictionary={translationsDictionary} />
      </div>
    </section>
  );
}
