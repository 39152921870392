'use client';

import { PortableText } from '@portabletext/react';
import { useSmoothScrollTo } from 'ui/hooks';
import { type TranslationsDictionary } from '@lib/i18n';
import type { BasicProductDocument } from '@lib/sanity/types';
import ListProducts from './list-products';

type Props = {
  data: {
    anchor?: string;
    bgColor?: string;
    headline?: string;
    subheadline?: [];
    products?: BasicProductDocument[];
    warning?: [];
  };
  translationsDictionary: TranslationsDictionary;
  buttonText?: string;
  buttonVariant?: 'text' | 'contained' | 'outlined';
};

export default function FeaturedProducts({
  data,
  translationsDictionary,
  buttonText,
  buttonVariant,
}: Props): JSX.Element | null {
  const bind = useSmoothScrollTo(`#${data.anchor}`);
  if (!data.products || data.products.length === 0) {
    return null;
  }

  return (
    <section
      className={`flex flex-col w-full justify-center items-center px-6 md:px-10 lg:px-16 py-8 sm:py-10 lg:py-20 ${
        data.bgColor ?? 'bg-white'
      }`}
      id={data.anchor}
      {...(data.anchor ? { ...bind } : null)}
    >
      <div className="flex w-full justify-center max-w-screen-lg">
        <div className="flex gap-x-8 flex-col w-full max-w-screen-md">
          {data.headline ? (
            <h3 className="pb-2 md:pb-5 text-2xl lg:text-4xl text-primary font-bold leading-[1.875rem] lg:leading-10 text-left sm:text-center">
              {data.headline}
            </h3>
          ) : null}
          {data.subheadline ? (
            <div className="pb-6 md:pb-8 text-sm md:text-base leading-6 text-black-base text-left sm:text-center">
              <PortableText value={data.subheadline} />
            </div>
          ) : null}
        </div>
      </div>
      <ListProducts
        buttonText={buttonText}
        buttonVariant={buttonVariant}
        products={data.products}
        translationsDictionary={translationsDictionary}
      />
      {data.warning ? (
        <div className="flex w-full justify-center px-6 md:px-10 lg:px-16 py-6">
          <div className="text-xs leading-4 text-black-base text-center max-w-screen-md">{data.warning}</div>
        </div>
      ) : null}
    </section>
  );
}
