import type { Locale, Rewrite } from './types';

/**
 * Array of sites/languages that are supported.
 * A site can be a domain or a directory (e.g. /es/ or fisiocrem.es)
 */
export const locales: Locale[] = [
  { id: 'en-GB', title: 'English (UK)', path: 'gb', domain: 'fisiocrem.eu', isDefault: true },
  { id: 'ro-RO', title: 'Romanian (RO)', domain: 'fisiocrem.ro', path: 'ro' },
];

/**
 * Array of rewrites to be applied in the middleware
 */
export const rewrites: Rewrite[] = [
  { source: '/productos/', destination: '/es/products/' },
  { source: '/es/productos/', destination: '/es/products/' },
  { source: '/produse/', destination: '/ro/products/' },
  { source: '/ro/produse/', destination: '/ro/products/' },
];
