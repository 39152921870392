'use client';

import Rating from '@mui/material/Rating';
import { PortableText } from '@portabletext/react';
import { useSmoothScrollTo } from 'ui/hooks';
import type { ReviewSmallDocument } from '@lib/sanity/types';
import { EmptyStarIcon, StarIcon } from '@public/icons';

type Props = {
  data?: ReviewSmallDocument;
};

export default function ReviewSmall({ data }: Props): JSX.Element | null {
  const bind = useSmoothScrollTo(`#${data?.anchor}`);
  if (!data) {
    return null;
  }
  return (
    <section
      className={`flex flex-col w-full justify-center items-center py-3 md:py-7 ${
        data.bgColor ? data.bgColor : 'bg-white'
      }`}
      id={data.anchor}
      {...(data.anchor ? { ...bind } : null)}
    >
      <div className="flex flex-col md:flex-row text-xs md:text-base text-black-base text-left md:text-center gap-2 justify-end items-center max-w-screen-lg font-normal">
        <div className="flex flex-row gap-2">
          <PortableText value={data.review?.title ?? []} />
          <Rating
            emptyIcon={<EmptyStarIcon className="ml-1 h-5 w-5" />}
            icon={<StarIcon className="ml-1 h-5 w-5" />}
            name="read-only"
            readOnly
            value={data.review?.count}
          />
        </div>
        <div className="flex ml-2">
          <PortableText value={data.review?.text ?? []} />
        </div>
      </div>
    </section>
  );
}
