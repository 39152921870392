'use client';

import { useState } from 'react';
import type { SyntheticEvent } from 'react';
import { PortableText, toPlainText } from '@portabletext/react';
import type { PortableTextBlock } from '@portabletext/types';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material';
import { MinusCircleIcon, PlusCircleIcon } from '@public/icons';

export type FaqsSection = {
  bgColor?: string;
  headline?: string;
  label?: string;
  faqs?: {
    _key: string;
    question: string;
    answer: PortableTextBlock;
  }[];
  ctaText?: string;
  ctaHref?: string;
  brandImage?: {
    asset: {
      _type: string;
      _ref: string;
    };
    lqip: string;
  };
  _id?: string;
};

type Props = {
  data?: FaqsSection;
};

export default function Faqs({ data }: Props): JSX.Element | null {
  const [expanded, setExpanded] = useState<string | false>('');

  const handleChange = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (!data?.faqs) {
    return null;
  }

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: data.faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: toPlainText(faq.answer),
      },
    })),
  };

  return (
    <section
      className={`flex flex-col w-full justify-center items-center py-8 sm:py-12 lg:py-20 ${
        data.bgColor ? data.bgColor : 'bg-white'
      }`}
    >
      <script dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} type="application/ld+json" />

      <div className="flex w-full justify-center px-6 md:px-10 lg:px-16">
        <div className="flex gap-x-8 flex-col justify-center items-center w-full max-w-screen-md">
          {data.headline ? (
            <h3 className="w-full pb-4 lg:pb-8 text-2xl lg:text-4xl text-primary font-bold leading-7 lg:leading-10 text-center">
              {data.headline}
            </h3>
          ) : null}
          {data.faqs.map((faq, index) => {
            const currentPanel = `panel${index}`;
            const isExpanded = expanded === currentPanel;
            return (
              <Accordion expanded={isExpanded} key={faq._key} onChange={handleChange(currentPanel)}>
                <AccordionSummary
                  aria-controls={`${currentPanel}-content`}
                  className="px-0 sm:px-4"
                  expandIcon={
                    isExpanded ? (
                      <MinusCircleIcon className="pr-3 w-8 h-5 text-primary" />
                    ) : (
                      <PlusCircleIcon className="pr-3 w-8 h-5 text-primary" />
                    )
                  }
                  id={`${currentPanel}-header`}
                >
                  <div className="w-full text-base">{faq.question}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="text-sm">
                    <PortableText value={faq.answer} />
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
          {data.ctaText && data.ctaHref ? (
            <Button className="mt-8" color="secondary" disableElevation href={data.ctaHref} variant="contained">
              {data.ctaText}
            </Button>
          ) : null}
        </div>
      </div>
    </section>
  );
}
