import CartIcon from './cart.svg';
import ChatIcon from './chat.svg';
import EmptyStarIcon from './empty-star.svg';
import FacebookIcon from './facebook.svg';
import InstagramIcon from './instagram.svg';
import LocationIcon from './location.svg';
import MinusCircleIcon from './minus-circle.svg';
import PadlockIcon from './padlock.svg';
import PhoneCallIcon from './phone-call.svg';
import PlusCircleIcon from './plus-circle.svg';
import QuestionIcon from './question.svg';
import SecurePayIcon from './secure-pay.svg';
import StarIcon from './star.svg';
import UserIcon from './user.svg';
import YoutubeIcon from './youtube.svg';

export {
  CartIcon,
  ChatIcon,
  EmptyStarIcon,
  FacebookIcon,
  InstagramIcon,
  LocationIcon,
  MinusCircleIcon,
  PadlockIcon,
  PhoneCallIcon,
  PlusCircleIcon,
  QuestionIcon,
  SecurePayIcon,
  StarIcon,
  UserIcon,
  YoutubeIcon,
};
