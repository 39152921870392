'use client';

import { useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import { CorporateFooterButton, CorporateFooterPanel, useCookieConfigModal } from 'ui/components';
import { FacebookIcon, InstagramIcon, YoutubeIcon } from '@public/icons';
import { useTranslation, type TranslationsDictionary } from '@lib/i18n';
import type { Settings } from '@lib/sanity/types';
import { locales } from '@lib/i18n/locales';

type SocialNetworkLink = { alt: string; href: string; icon: React.ComponentType<React.SVGProps<SVGSVGElement>> };

// @TO-DO Put the real social networks when ready
const rrss = [
  {
    alt: 'Síguenos en Instagram',
    href: 'https://www.instagram.com/fisiocrem_es/',
    icon: InstagramIcon,
  },
  {
    alt: 'Síguenos en YouTube',
    href: 'https://www.youtube.com/@fisiocremespana5960',
    icon: YoutubeIcon,
  },
  {
    alt: 'Síguenos en Facebook',
    href: 'https://www.facebook.com/FisiocremES/',
    icon: FacebookIcon,
  },
];
const socialNetworks: { 'gb': SocialNetworkLink[]; 'ro': SocialNetworkLink[] } = {
  gb: rrss,
  ro: rrss,
};

export default function Footer({ dict, config }: { dict: TranslationsDictionary; config?: Settings }): JSX.Element {
  const [isCorporateFooterOpen, setIsCorporateFooterOpen] = useState(false);
  const { setIsConfigOpen } = useCookieConfigModal();
  const t = useTranslation(dict);
  const handleCorporateFooter = (): void => {
    setIsCorporateFooterOpen((prevState) => !prevState);
  };
  const currentLocale = locales.find((locale) => locale.id === config?.language)?.path;

  const handleChange = (event: SelectChangeEvent): void => {
    locales.forEach((locale) => {
      if (locale.id === event.target.value) {
        if (locale.domain) {
          const redirection = `https://www.${locale.domain}`;
          window.location.href = redirection;
        } else {
          window.location.href = `${window.location.protocol}://${window.location.host}/${locale.path}`;
        }
      }
    });
  };

  return (
    <>
      <footer className="flex justify-center w-full overflow-hidden px-4 pt-8 md:px-10 lg:px-16 bg-primary text-white">
        <div className="flex flex-wrap w-full max-w-screen-lg gap-x-24">
          <div className="flex flex-row w-full justify-center">
            <div className="flex flex-col items-center w-full sm:w-[12rem]">
              <div className="flex justify-center h-10 md:h-10">
                <Link
                  aria-label="Página principal de Fisiocrem"
                  className="flex justify-center grow lg:grow-0 h-full focus-visible:outline-white focus-visible:outline-2 focus-visible:outline-offset-[-2px] z-10"
                  href="/"
                >
                  <Image alt="Fisiocrem" className="w-auto h-10" height={40} src="/logos/logo-full.png" width={156} />
                </Link>
              </div>
              <ul className="flex w-full max-w-44 justify-center md:justify-between py-6 gap-9">
                {socialNetworks[currentLocale as 'gb' | 'ro'].map((socialNetwork) => (
                  <li key={socialNetwork.alt}>
                    <a
                      aria-label={socialNetwork.alt}
                      className="flex justify-center items-center w-9 h-9 bg-white text-primary rounded-full p-2"
                      href={socialNetwork.href}
                      rel="nofollow noreferrer"
                      target="_blank"
                    >
                      <socialNetwork.icon height={20} width={20} />
                    </a>
                  </li>
                ))}
              </ul>
              <FormControl className="w-full max-w-44">
                <Select
                  className="bg-white h-10 text-black"
                  name="site-selector"
                  onChange={handleChange}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'inherit',
                    },
                  }}
                  value={config?.language}
                >
                  {locales.map((locale) => {
                    return (
                      <MenuItem key={locale.id} value={locale.id}>
                        {locale.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row grow gap-x-20 pt-8 sm:pt-0 text-white w-full">
            <ul className="flex flex-col sm:flex-row md:flex-row lg:flex-row gap-6 md:gap-8 mt-2 sm:mt-10 justify-center w-full">
              {config?.mainMenuItems?.map((item) => (
                <li className="text-center sm:text-left" key={item.label}>
                  <Link className="text-inherit text-lg leading-6 font-medium" href={item.href}>
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col flex-wrap md:flex-row lg:flex-nowrap w-full pt-6 text-[0.75rem] md:text-xs leading-[1.125rem] font-normal">
            <div className="flex flex-col sm:flex-row w-full">
              <div className="flex w-full sm:w-auto py-3 px-4 justify-center items-center text-center md:text-left lg:whitespace-nowrap">
                &copy;&nbsp;{t('footer.copyright')}
              </div>
              <ul className="flex flex-col sm:flex-row md:flex-row lg:flex-row lg:flex-wrap grow sm:grow-0 lg:grow sm:ml-auto justify-center items-center sm:px-4 text-center lg:justify-center gap-x-4 gap-3">
                {legalLinks(config?.legalMenuItems || [], t('footer.manageCookie'), setIsConfigOpen).map(
                  (legalLink) => (
                    <li key={legalLink.label}>
                      {legalLink.href ? (
                        <Link className="inline-block text-inherit whitespace-nowrap" href={legalLink.href}>
                          {legalLink.label}
                        </Link>
                      ) : (
                        <Button
                          className="p-0 text-inherit text-xs font-normal whitespace-nowrap normal-case rounded-sm hover:underline focus:underline focus-visible:outline focus-visible:outline-white focus-visible:outline-1 focus-visible:outline-offset-[2px]"
                          disableFocusRipple
                          disableRipple
                          onClick={legalLink.onClick}
                        >
                          {legalLink.label}
                        </Button>
                      )}
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="flex w-full lg:w-auto justify-center items-end pt-5 lg:pt-0">
              <CorporateFooterButton
                className="bg-white hover:bg-white"
                isOpen={isCorporateFooterOpen}
                onClick={handleCorporateFooter}
                variant="color-logo"
              />
            </div>
          </div>
        </div>
      </footer>
      <CorporateFooterPanel bgColor="bg-white" color="text-primary" isOpen={isCorporateFooterOpen} />
    </>
  );
}

function legalLinks(
  config: { label: string; href?: string }[],
  cookieLabel: string,
  setIsCookieConfigModalOpen: Dispatch<SetStateAction<boolean>>
): { label: string; href?: string; onClick?: () => void }[] {
  return [
    ...config,
    {
      label: cookieLabel,
      onClick: () => {
        setIsCookieConfigModalOpen(true);
      },
    },
  ];
}
