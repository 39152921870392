import createImageUrlBuilder from '@sanity/image-url';
import type { Image, ImageUrlBuilder } from 'sanity';
import { dataset, projectId } from './env';

const imageBuilder = createImageUrlBuilder({
  projectId: projectId || '',
  dataset: dataset || '',
});

export const urlForImage = (source: Image): ImageUrlBuilder => {
  return imageBuilder.image(source).auto('format').fit('max');
};
